<template>
    <div class="loader_box">
        <div class="loaderDiv" :style="getLoaderColor(selectedColor)"></div>
        <!-- <div class="lds-ring" :style="getLoaderColor(selectedColor)">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div> -->
    </div>
</template>

<script>
export default {

    props:['selectedColor'],

    methods:{
        getLoaderColor(color){
            return `--loaderColor:${color}`
        }
    }
};
</script>

<style scoped>
.loader_box{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    z-index:999
}

.loaderDiv {

width: 230px;

height: 15px;

border-radius: 20px;

color: var(--loaderColor);

border: 2px solid;

position: relative;

}

.loaderDiv::before {

content: "";

position: absolute;

margin: 2px;

inset: 0 100% 0 0;

border-radius: inherit;

background: currentColor;

animation: l6 3s infinite;

}

@keyframes l6 {

100% {inset:0}

}
/* .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid var(--loaderColor);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--loaderColor) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
} */
</style>
